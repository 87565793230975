import { none, useState } from '@hookstate/core';
import { Validation } from '@hookstate/validation';
import Button from '@material-ui/core/Button';
import { CardProps } from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Labels } from './Labels';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 300,
      maxWidth: 350,
    },
    fields: {
      marginTop: theme.spacing(1.5),
      width: '100%',
    },
    formControl: {
      minWidth: 120,
      width: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    fieldset: {
      borderColor: '#888',
      borderRadius: '.25rem',
      borderWidth: '1px',
      margin: '.25rem 0',
    },
    legend: {
      fontSize: '0.8175em',
      marginLeft: '-.15rem',
      padding: '0 .25rem',
      color: '#aaa',
    },
  })
);

export interface CreateVirtualMachineFields {
  name: string;
  nameStarted: boolean;
  image: string;
  cpus: number;
  ram: number;
  diskSpace: number | string;
  project: string;
  region: string;
  zone: string;
  labels: Record<string, string>;
}

export interface CreateVirtualMachineProps extends Partial<CardProps> {
  onCreate?: (data: CreateVirtualMachineFields) => void;
  onCancel?: () => void;
}

export const CreateVirtualMachine: React.FC<CreateVirtualMachineProps> = ({
  children,
  onCreate,
  onCancel,
}) => {
  const classes = useStyles();
  const formSubmitted = useState<boolean>(false);
  const showNewLabelFields = useState(false);
  const newLabelKey = useState('');
  const newLabelValue = useState('');
  const labelKeyErrors = useState<Record<string, string>>({});
  const labelValueErrors = useState<Record<string, string>>({});
  const formState = useState<CreateVirtualMachineFields>({
    name: '',
    nameStarted: false,
    image: 'ubuntu16.04-template',
    cpus: 1,
    ram: 1,
    diskSpace: 40,
    project: 'anthos-paas',
    region: 'chaska',
    zone: 'pod6',
    labels: {
      project: 'anthos-paas',
      region: 'chaska',
      zone: 'pod6',
    },
  });
  formState.attach(Validation);

  Validation(formState.name).validate(
    (name) =>
      name.replace(/\s+/g, '') !== '' &&
      !!name.match(/[a-zA-Z]/) &&
      !!name.match(/^[a-zA-Z0-9-]+$/),
    'Machine name is required, must contain at least one letter, and can only contain letters, numbers or -'
  );

  Validation(formState.diskSpace).validate(
    (diskSpace) => !isNaN(parseFloat(`${diskSpace}`)) && diskSpace >= 40,
    'Disk space must be a number greater than or equal to 40'
  );

  Validation(formState.labels).validate(
    () =>
      Object.keys(labelKeyErrors.value).length === 0 &&
      Object.keys(labelValueErrors).length === 0,
    'Please correct the label errors'
  );

  const handleSubmit = () => {
    if (!Validation(formState).valid()) {
      formState.nameStarted.set(true);
      return;
    }

    if (newLabelKey.value) {
      formState.labels[newLabelKey.value].set(newLabelValue.value);
      clearNewLabelInputs();
    }

    showNewLabelFields.set(false);

    formSubmitted.set(true);

    onCreate && onCreate(JSON.parse(JSON.stringify(formState.value)));
  };

  const handleCancel = () => {
    onCancel && onCancel();
  };

  const textError =
    formState.nameStarted.value &&
    !Validation(formState.name).valid() &&
    Validation(formState.name).errors()[0]?.message;

  const diskSpaceError =
    !Validation(formState.diskSpace).valid() &&
    Validation(formState.diskSpace).errors()[0]?.message;

  const reservedKeys = ['project', 'region', 'zone'];

  const handleNewLabelKeyChange = (keyVal: string) => {
    newLabelKey.set(keyVal);
    labelKeyErrors['new-label-key'].set(none);

    if (reservedKeys.includes(keyVal)) {
      labelKeyErrors['new-label-key'].set(
        `The key "${keyVal}" is reserved by the system. Please use a different key.`
      );
      return;
    }

    if (keyVal.includes(':')) {
      labelKeyErrors['new-label-key'].set('key cannot contain ":"');
      return;
    }

    if (keyVal && formState.labels.value[keyVal]) {
      labelKeyErrors['new-label-key'].set(
        `The key "${keyVal}" is already present`
      );
    }
  };

  const clearNewLabelInputs = () => {
    newLabelKey.set('');
    newLabelValue.set('');
  };

  const handleNewLabelClearClick = () => {
    clearNewLabelInputs();
    showNewLabelFields.set(false);
  };

  const handleAddLabelClick = () => {
    if (newLabelKey.value) {
      formState.labels.merge({
        [newLabelKey.value]: newLabelValue.value,
      });
    }
    clearNewLabelInputs();
    showNewLabelFields.set(true);
  };

  const handleRemoveLabelClick = (key: string) => {
    formState.labels[key]?.set(none);
    labelKeyErrors[key]?.set(none);
    labelValueErrors[key]?.set(none);
  };

  const handleLabelValueChange = (key: string, value: string) => {
    if (key === 'new-label-key') {
      newLabelValue.set(value);
    } else {
      formState.labels.merge({ [key]: value });
    }

    labelValueErrors[key].set(none);

    if (value.includes(':')) {
      labelValueErrors[key].set('value cannot contain ":"');
      return;
    }
  };

  const { project, region, zone, ...customLabels } = formState.labels.value;

  return (
    <>
      <CardContent>
        <Typography
          variant={'h5'}
          style={{ fontFamily: 'Futura, Tw Cen MT', marginBottom: '1rem' }}
          color="textSecondary"
        >
          Create Virtual Machine
        </Typography>
        <TextField
          className={classes.fields}
          id="create-virtual-machine-name"
          label="Virtual Machine Name"
          variant="outlined"
          autoFocus
          inputProps={{ maxlength: 80 }}
          value={formState.name.value}
          error={Boolean(textError)}
          helperText={textError}
          onChange={(event) =>
            formState.merge({ name: event.target.value, nameStarted: true })
          }
        />
        <FormControl variant="outlined" className={classes.fields}>
          <InputLabel id="create-virtual-machine-type">Image/OS</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={formState.image.value}
            onChange={(event) =>
              formState.image.set(event.target.value as string)
            }
            label="Image"
          >
            <MenuItem value={'ubuntu16.04-template'}>Ubuntu 16.04</MenuItem>
            <MenuItem value={'ubuntu18.04-template'}>Ubuntu 18.04</MenuItem>
            <MenuItem value={'windows-2016-template'}>Windows 2016</MenuItem>
          </Select>
        </FormControl>
        <div style={{ display: 'flex' }}>
          <FormControl variant="outlined" className={classes.fields}>
            <InputLabel id="create-virtual-machine-cpus">CPUs</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={formState.cpus.value}
              onChange={(event) =>
                formState.cpus.set(event.target.value as number)
              }
              label="CPUs"
            >
              <MenuItem value={1}>One (1)</MenuItem>
              <MenuItem value={2}>Two (2)</MenuItem>
              <MenuItem value={3}>Three (3)</MenuItem>
              <MenuItem value={4}>Four (4)</MenuItem>
              <MenuItem value={5}>Five (5)</MenuItem>
              <MenuItem value={6}>Six (6)</MenuItem>
              <MenuItem value={7}>Seven (7)</MenuItem>
              <MenuItem value={8}>Eight (8)</MenuItem>
              <MenuItem value={9}>Nine (9)</MenuItem>
              <MenuItem value={10}>Ten (10)</MenuItem>
            </Select>
          </FormControl>
          <div style={{ width: '2rem' }}></div>
          <FormControl variant="outlined" className={classes.fields}>
            <InputLabel id="create-virtual-machine-ram">Memory</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={formState.ram.value}
              onChange={(event) =>
                formState.ram.set(event.target.value as number)
              }
              label="Memory"
            >
              <MenuItem value={0.5}>0.5 GB</MenuItem>
              <MenuItem value={1}>1 GB</MenuItem>
              <MenuItem value={1.5}>1.5 GB</MenuItem>
              <MenuItem value={2}>2 GB</MenuItem>
              <MenuItem value={3}>3 GB</MenuItem>
              <MenuItem value={4}>4 GB</MenuItem>
              <MenuItem value={6}>6 GB</MenuItem>
              <MenuItem value={8}>8 GB</MenuItem>
              <MenuItem value={12}>12 GB</MenuItem>
              <MenuItem value={14}>14 GB</MenuItem>
              <MenuItem value={16}>16 GB</MenuItem>
            </Select>
          </FormControl>
          <div style={{ width: '2rem' }}></div>
          <FormControl variant="outlined" className={classes.fields}>
            <TextField
              id="disk-space"
              label="Disk Space (GB)"
              type="number"
              variant="outlined"
              value={formState.diskSpace.value}
              InputLabelProps={{ shrink: true }}
              inputProps={{ min: 40, step: '1.0' }}
              error={Boolean(diskSpaceError)}
              helperText={diskSpaceError}
              onChange={(e) => formState.merge({ diskSpace: e.target.value })}
            />
          </FormControl>
        </div>
        <FormControl variant="outlined" className={classes.fields}>
          <InputLabel id="project">Project</InputLabel>
          <Select
            labelId="project-label"
            id="project-select-id"
            value={formState.project.value}
            onChange={(event) =>
              formState.project.set(event.target.value as string)
            }
            label="Project"
          >
            <MenuItem value="anthos-paas">Anthos PAAS</MenuItem>
            <MenuItem value="billing">Billing</MenuItem>
            <MenuItem value="cc">CC</MenuItem>
            <MenuItem value="hrms">HRMS</MenuItem>
            <MenuItem value="inventory">Inventory</MenuItem>
            <MenuItem value="sap">SAP</MenuItem>
          </Select>
        </FormControl>
        <div style={{ display: 'flex' }}>
          <FormControl variant="outlined" className={classes.fields}>
            <InputLabel id="region">Region</InputLabel>
            <Select
              labelId="region-label"
              id="region-select-id"
              value={formState.region.value}
              onChange={(event) =>
                formState.region.set(event.target.value as string)
              }
              label="Region"
            >
              <MenuItem value="chaska">Chaska</MenuItem>
            </Select>
          </FormControl>
          <div style={{ width: '2rem' }}></div>
          <FormControl variant="outlined" className={classes.fields}>
            <InputLabel id="zone">Zone</InputLabel>
            <Select
              labelId="zone-label"
              id="zone-select-id"
              value={formState.zone.value}
              onChange={(event) =>
                formState.zone.set(event.target.value as string)
              }
              label="Zone"
            >
              <MenuItem value="pod6">Pod 6</MenuItem>
            </Select>
          </FormControl>
        </div>
        <fieldset className={classes.fieldset}>
          <legend className={classes.legend}>Labels</legend>
          <Labels
            labels={customLabels}
            labelKeyErrors={labelKeyErrors.value}
            labelValueErrors={labelValueErrors.value}
            newLabelKey={newLabelKey.value}
            newLabelValue={newLabelValue.value}
            onAddLabelClick={handleAddLabelClick}
            onLabelValueChange={handleLabelValueChange}
            onNewLabelClearClick={handleNewLabelClearClick}
            onNewLabelKeyChange={handleNewLabelKeyChange}
            onRemoveLabelClick={handleRemoveLabelClick}
            showNewLabelFields={showNewLabelFields.value}
          />
        </fieldset>
        {children}
      </CardContent>
      <CardActions style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={() => handleCancel()}>Cancel</Button>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={() => handleSubmit()}
          disabled={!Validation(formState).valid() || formSubmitted.value}
        >
          Create
        </Button>
      </CardActions>
    </>
  );
};
