import {
  Box,
  Button,
  createStyles,
  FormControl,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableRow,
  TextField,
  Theme,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fields: {
      width: '100%',
    },
    closeIcon: {
      color: 'rgba(0,0,0,0.54)',
      cursor: 'hover',
    },
  })
);

interface Column {
  id: 'key' | 'value' | 'close-icon';
  label: string;
  minWidth?: number;
  align?: TableCellProps['align'];
}

const columns: Column[] = [
  {
    id: 'key',
    label: 'Key',
    minWidth: 80,
  },
  {
    id: 'value',
    label: 'Value',
    minWidth: 80,
  },
  {
    id: 'close-icon',
    label: '',
    minWidth: 40,
    align: 'center',
  },
];

export interface LabelsProps {
  labels: Record<string, string>;
  labelKeyErrors: Record<string, string>;
  labelValueErrors: Record<string, string>;
  newLabelKey: string;
  newLabelValue: string;
  onAddLabelClick: () => void;
  onLabelValueChange: (key: string, value: string) => void;
  onNewLabelKeyChange: (keyVal: string) => void;
  onNewLabelClearClick: () => void;
  onRemoveLabelClick: (key: string) => void;
  showNewLabelFields: boolean;
}

export const Labels: React.FC<LabelsProps> = ({
  labels,
  labelKeyErrors,
  labelValueErrors,
  newLabelKey,
  newLabelValue,
  onAddLabelClick,
  onLabelValueChange,
  onNewLabelKeyChange,
  onNewLabelClearClick,
  onRemoveLabelClick,
  showNewLabelFields,
}) => {
  const classes = useStyles();
  return (
    <FormControl variant="outlined" className={classes.fields}>
      {(showNewLabelFields || !!Object.keys(labels).length) && (
        <TableContainer>
          <Table aria-label="labels">
            <TableBody>
              {Object.entries(labels).map(([key, value], i) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={`custom-label-${i}`}
                  >
                    <TableCell align={columns[0].align}>{key}</TableCell>
                    <TableCell align={columns[1].align}>
                      <TextField
                        value={value}
                        onChange={(e) =>
                          onLabelValueChange(key, e.target.value)
                        }
                        error={!!labelValueErrors[key]}
                        helperText={labelValueErrors[key] || ''}
                      />
                    </TableCell>
                    <TableCell
                      key={`remove-label-${i}`}
                      align={columns[2].align}
                    >
                      <IconButton onClick={() => onRemoveLabelClick(key)}>
                        <CloseIcon className={classes.closeIcon} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
              {showNewLabelFields && (
                <TableRow hover tabIndex={-1}>
                  <TableCell align={columns[0].align}>
                    <TextField
                      id="new-key"
                      label="Key"
                      value={newLabelKey}
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => onNewLabelKeyChange(e.target.value)}
                      error={!!labelKeyErrors['new-label-key']}
                      helperText={labelKeyErrors['new-label-key'] || ''}
                    />
                  </TableCell>
                  <TableCell align={columns[1].align}>
                    <TextField
                      id="new-value"
                      label="Value"
                      value={newLabelValue}
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) =>
                        onLabelValueChange('new-label-key', e.target.value)
                      }
                      error={!!labelValueErrors['new-label-key']}
                      helperText={labelValueErrors['new-label-key'] || ''}
                    />
                  </TableCell>
                  <TableCell align={columns[2].align}>
                    <IconButton onClick={() => onNewLabelClearClick()}>
                      <CloseIcon className={classes.closeIcon} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Box>
        <Button
          color={'primary'}
          style={{ width: '100%' }}
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => onAddLabelClick()}
          disabled={
            showNewLabelFields === true &&
            (!newLabelKey ||
              !!labelKeyErrors['new-label-key'] ||
              !!labelValueErrors['new-label-key'])
          }
        >
          Add Label
        </Button>
      </Box>
    </FormControl>
  );
};
