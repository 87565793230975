import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useKeycloak } from '@react-keycloak/web';
import React, { FunctionComponent, useEffect, useState } from 'react';

import {
  CreateContainer,
  CreateContainerFields,
} from '../controls/CreateContainer';
import {
  CreateVirtualMachine,
  CreateVirtualMachineFields,
} from '../controls/CreateVirtualMachine';
import VmTable from '../vm-table/VmTable';
import ContainerTable from './container-table';

export interface VirtualizationProps {
  handleClickOpen: () => void;
  handleClose: () => void;
  open?: boolean;
  handleChange?: (data: CreateVirtualMachineFields) => Promise<any>;
  createContainerOpen: boolean;
  handleCreateContainerOpen: () => void;
  handleCreateContainerClose: () => void;
  handleCreateContainerChange: (data: CreateContainerFields) => Promise<any>;
  getVmList: () => Promise<any>;
  getContainerList: () => Promise<any>;
}

export interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  className: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainGrid: {
      padding: theme.spacing(1, 2, 0, 2),
    },
    buttonTextFix: {
      marginTop: '.15rem',
    },
    pageToolbar: {
      display: 'flex',
      height: '3.5rem',
      padding: '0 1.65rem .5rem 1rem',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: theme.palette.text.primary,
    },
    pageHeader: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      fontWeight: 300,
      fontSize: '1.25rem',
    },
    pageTitle: {
      fontFamily: 'Futura, Tw Cen MT',
    },
    spc: {
      width: '1rem',
    },
    tab: {
      padding: '0 1rem',
    },
    tabPanelBorder: {
      borderTop: '1px solid rgba(0,0,0,0.15)',
    },
  })
);

let vmListIntervalId: ReturnType<typeof setInterval>;
let containerListIntervalId: ReturnType<typeof setInterval>;

export const Virtualization: FunctionComponent<VirtualizationProps> = (
  props
) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleTabChange = (_: unknown, newValue: number) => {
    setValue(newValue);
  };

  const { keycloak } = useKeycloak();

  const [vmList, setVmList] = useState([]);
  useEffect(() => {
    async function loadVms() {
      const theVmList = await props.getVmList();
      setVmList(theVmList);
    }
    loadVms();
    vmListIntervalId = setInterval(async () => {
      await loadVms();
    }, 5000);
    return () => clearInterval(vmListIntervalId);
  }, []);

  const [containerList, setContainerList] = useState([]);
  useEffect(() => {
    async function loadContainers() {
      const theList = await props.getContainerList();
      setContainerList(theList);
    }
    loadContainers();
    containerListIntervalId = setInterval(async () => {
      await loadContainers();
    }, 5000);
    return () => clearInterval(containerListIntervalId);
  }, []);
  return (
    <Box>
      <Box className={classes.pageToolbar}>
        <div className={classes.pageHeader}>
          <AccountTreeIcon />
          <div className={classes.spc}></div>
          <div className={classes.pageTitle}>Workloads</div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            endIcon={<AddCircleOutlineIcon />}
            onClick={props.handleCreateContainerOpen}
          >
            <span className={classes.buttonTextFix}>Create Container</span>
          </Button>
          <div className={classes.spc}></div>
          {keycloak.hasRealmRole('admin') && (
            <>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                endIcon={<AddCircleOutlineIcon />}
                onClick={props.handleClickOpen}
              >
                <span className={classes.buttonTextFix}>Create VM</span>
              </Button>
              <div className={classes.spc}></div>
            </>
          )}
          {/* <Button
            size="small"
            variant="outlined"
            color="secondary"
            endIcon={<OpenInNewIcon />}
            onClick={() =>
              window.open(
                'https://intersight.com/an/virtualization/virtualization/datacenter/',
                '_blank'
              )
            }
          >
            <span className={classes.buttonTextFix}>Intersight</span>
          </Button> */}
          {/* <div className={classes.spc}></div> */}
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            endIcon={<OpenInNewIcon />}
            onClick={() =>
              window.open('https://172.24.4.19/dashboard/#!/login', '_blank')
            }
          >
            <span className={classes.buttonTextFix}>Platform</span>
          </Button>
        </div>
      </Box>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Tabs value={value} onChange={handleTabChange}>
          <Tab label="Virtual Machines" className={classes.tab} />
          <Tab label="Containers" className={classes.tab} />
        </Tabs>
        <TabPanel value={value} index={0} className={classes.tabPanelBorder}>
          <VmTable vmList={vmList} />
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.tabPanelBorder}>
          <ContainerTable containerList={containerList} />
        </TabPanel>
      </div>
      <Dialog
        open={!!props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <CreateVirtualMachine
            onCancel={props.handleClose}
            onCreate={props.handleChange}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={!!props.createContainerOpen}
        onClose={props.handleCreateContainerClose}
        aria-labelledby="create-container-dialog"
      >
        <DialogContent>
          <CreateContainer
            onCancel={props.handleCreateContainerClose}
            onCreate={props.handleCreateContainerChange}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Virtualization;
