import { CircularProgress, TableSortLabel } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { navigate } from '@reach/router';
import React, { FunctionComponent } from 'react';

import formatBytes from '../../util/formatBytes';

type Order = 'asc' | 'desc';

enum PowerStateEnum {
  Unknown,
  PoweredOn,
  PoweredOff,
  StandBy,
  Paused,
}

interface Column {
  id:
    | 'diskCapacity'
    | 'memoryCapacity'
    | 'numCpus'
    | 'powerState'
    | 'tags'
    | 'vmName';
  label: string;
  minWidth?: number;
  align?: TableCellProps['align'];
  format?: (value: number) => string;
  sort: (a: VM, b: VM) => number;
}

const columns: Column[] = [
  {
    id: 'vmName',
    label: 'VM Name',
    minWidth: 120,
    sort: (a: VM, b: VM) => a.vmName.localeCompare(b.vmName),
  },
  {
    id: 'tags',
    label: 'Tags',
    minWidth: 120,
    align: 'left',
    sort: (a: VM, b: VM) => a.tags.localeCompare(b.tags),
  },
  {
    id: 'powerState',
    label: 'Power State',
    minWidth: 80,
    align: 'right',
    sort: (a: VM, b: VM) => a.powerState - b.powerState,
    format: (v) => PowerStateEnum[v] || 'Unknown',
  },
  {
    id: 'numCpus',
    label: 'Num CPUs',
    minWidth: 80,
    align: 'right',
    sort: (a: VM, b: VM) => a.numCpus - b.numCpus,
  },
  {
    id: 'memoryCapacity',
    label: 'Memory',
    minWidth: 80,
    align: 'right',
    sort: (a: VM, b: VM) => a.memoryCapacity - b.memoryCapacity,
    format: (v) => formatBytes(v),
  },
  {
    id: 'diskCapacity',
    label: 'Storage',
    minWidth: 80,
    align: 'right',
    sort: (a: VM, b: VM) => a.diskCapacity - b.diskCapacity,
    format: (v) => formatBytes(v),
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    minHeight: 440,
    height: '100%',
    width: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

interface VM {
  vmName: string;
  powerState: number;
  numCpus: number;
  memoryCapacity: number;
  diskCapacity: number;
  tags: string;
}

export interface VmTableProps {
  vmList: VM[];
}

export const VmTable: FunctionComponent<VmTableProps> = ({ vmList }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<Column['id']>('vmName');
  const sortRoutine = columns.find((c) => c.id === orderBy)?.sort;
  if (!sortRoutine) {
    throw new Error('sortRoutine not found');
  }

  const handleRequestSort = (property: Column['id']) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={() => handleRequestSort(column.id)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {vmList.length ? (
              vmList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .sort((a: VM, b: VM) =>
                  order === 'asc' ? sortRoutine(a, b) : -sortRoutine(a, b)
                )
                .map((vm, i) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={i}
                    onClick={async () => {
                      await navigate('/compute/vm-detail/', {
                        state: { vmname: vm.vmName },
                      });
                    }}
                  >
                    {columns.map((column, idx) => {
                      const value = vm[column.id];
                      const v =
                        column.format && typeof value === 'number'
                          ? column.format(value)
                          : value;
                      return (
                        <TableCell
                          key={`${column.id}-${i}-${idx}`}
                          align={column.align}
                        >
                          {v}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))
            ) : (
              <CircularProgress />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={vmList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default VmTable;
