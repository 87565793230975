import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/layout/Layout';
import SEO from '../../components/seo/SEO';
import Virtualization from '../../components/virtualization/Virtualization';
import useVirtualizationPage from '../../hooks/useVirtualizationPage';

export const VirtualizationPage = () => {
  const { t } = useTranslation();
  const {
    open,
    handleClickOpen,
    handleClose,
    handleChange,
    createContainerOpen,
    handleCreateContainerOpen,
    handleCreateContainerClose,
    handleCreateContainerChange,
    getContainerList,
    getVmList,
  } = useVirtualizationPage();

  return (
    <Layout>
      <SEO title={t('virtualization.title')} />
      <Virtualization
        {...{
          handleClickOpen,
          handleClose,
          open,
          handleChange,
          createContainerOpen,
          handleCreateContainerOpen,
          handleCreateContainerClose,
          handleCreateContainerChange,
          getContainerList,
          getVmList,
        }}
      />
    </Layout>
  );
};

export default VirtualizationPage;
